.main-hero{
    /* background-color: var(--dark-orange); */
    transition: 1s;
   /* border-radius: 30px; */
}
.main-hero-parent{
    /* position: absolute; */
    top: 0%;
    left: 50%;
    /* transform: translateX(-50%); */
    width: 100%;
    /* padding: 10px 3%; */
    padding-top: 10px !important;
}
.main-scroller-hero{
    position: relative;
}
.image-text{
    position: absolute;
    padding: 10px;
    border: 2px solid var(--black-3);
    background-color: var(--white);
    color: #151907;
    text-align: left;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    /* font-weight: 600; */
    transition: all .2s ease-in-out;
    box-shadow: rgb(0, 0, 0) 5px 5px, rgb(0, 0, 0) 10px 10px;
    width: 400px;
    bottom: 150px;
}
.hero-right{
    position: relative;
}
.marquee-container{
    transform: rotate(-10deg) scale(1.09);

}
.marquee-container p .c{
    color: transparent;
    -webkit-text-stroke: 2px var(--dark-orange);
    font-size: 7rem;
}
.marquee-container p{
    display: flex;
    gap: 30px;
    font-size: 6rem;
}
.m-text{
    display: flex;
    gap: 20px;
}
.google{
    /* border: 2px solid var(--black-3); */
    background-color: var(--white);
    color: #151907;
    text-align: left;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
 
}

.w-img{
    width: 80px;
    height: 80px;
}
.weareon img{
   width: 100%;
   height: 100%;
   object-fit: cover;
   filter: grayscale(100%);
    
}
.weareon img:hover{
    filter: grayscale(0%);
}
.hero-large-one{
    height: 300px;
    object-fit: cover;
}
.hero-points{
    list-style-type: none;
    padding: 0;
    display: flex !important;
    flex-wrap: wrap;
    gap: 10px;
}
.hero-points li{
    list-style-position: inside;
    padding: 10px;
    background: #a2a1a02d;
    border: 1px solid #151907;
    border-radius: 100px;
    color: #151907;
}
.hero-points li:hover{
    background-color: var(--black-3);
    color: #fff;
    cursor: pointer;
}
.hero-info{
    background-color: #9a9a9a2c;
}
.hero-company{
    background-color: #dbdbdb96;
    padding: 10px;
    border-radius: 20px;
}
.hero-company .company-card{
        height: 100px;
       
}
.hero-company .company-card img{
    height: 100% !important;
    object-fit: contain;
}
  
  @keyframes marquee {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  

@media (max-width:768px){
    .main-hero-parent{
        padding: 0px !important;
       
    }
    .main-hero{
         border-radius: 0px !important;
    }
    .image-text{
        width: 90%;
        left: 10px;
        bottom: 100px;
    }
    .hero-large-one{
        height: 200px !important;
    }
}
