.register-card{
    background-color: var(--gray3) !important;
    color: #fff;
}
.register-card form .form-control{
    border: none;
    background: var(--gray4) !important;
    border-radius: 20px !important;
    padding: 15px !important;
}
.register-card form .form-control:focus{
    box-shadow: none;
    border: 2px solid var(--gray4);
}