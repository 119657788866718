.benifits-card-body ul{
    /* display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%; */
}
.benifits-card{
    background-color: #fff;
    height: 100%;
    color: #000;
}
.ul ul{
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 30px;
    flex-wrap: wrap;
}
@media(max-width:768px){
    .ul ul{
        flex-direction: column;
    }
}
.salary-image{
    height: 200px;
    object-fit: cover;
}