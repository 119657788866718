.job-switch-guide {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

h1 {
  color: #2c3e50;
  margin-bottom: 20px;
}

h2 {
  color: #34495e;
  margin-top: 30px;
  margin-bottom: 15px;
}

h3 {
  color: #16a085;
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  margin-bottom: 15px;
}

ul {
  margin-bottom: 15px;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

strong {
  color: #2980b9;
}

.blog-page-wrapper {
  min-height: 100vh;
  background-color: #f8f9fa;
}

.card {
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.btn {
  transition: all 0.3s ease;
}

.btn:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.badge {
  padding: 0.5em 1em;
  font-weight: 500;
}

.blog-page {
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
}

.blog-page h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.blog-page h2 {
  font-size: 2rem;
  margin: 2rem 0 1rem;
}

.blog-page p {
  margin-bottom: 1.5rem;
}

.blog-page ul, .blog-page ol {
  margin-bottom: 1.5rem;
  padding-left: 2rem;
}

.blog-page li {
  margin-bottom: 0.5rem;
}