.course-faq {
    margin-bottom: 20px;
    background-color: #fff;
    padding: 15px;
}
.course-faq-question{
    padding: 10px;
}
.course-faq-question [class*="bi-"]{
    background-color: var(--blue-light);
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 100px;
    margin-right: 20px;
}
.faq-left{
    position: relative;
}
.faq-contact{
    position: absolute;
    bottom: 20px;
    right: 10px;
    background: var(--green);
}