.chat-with-us{
    /* background: url('../images//chat-with-us-bottom.svg') no-repeat bottom; */
    background-color: var(--gray2);
    background-size: cover;
    border-radius: 20px;
    position: relative;
}
.bar{
    padding: 2px 20px;
    padding-left: 50px;
    background-color: #fff;
    width: fit-content;
    margin-bottom: 10px;
}
.chat-with-us img{
    width: 100%;
}
.chat-btn{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
}