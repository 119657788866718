.left video{
    width: 100%;
    height: 95vh;
    object-fit: cover;
    border-radius: 30px;
    padding: 5px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
}
.skillsmain{
    padding: 4%;
    background: #FAF6E9 !important;
}
.text-right h1{
    font-size: 4em;
}
.section-blur, .section-blur2{
    filter: blur(90px);
    width: 100%;
    height: 7rem;
    border-radius: 100%;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
    background-color: rgba(235, 20, 31, 0.612) !important;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: 200px;
}
.section-blur2{
    bottom: 0%;
    left: 0%;
    width: 100px;
    height: 100px;
    background-color: #eede2788;
}
.video{
    width: fit-content !important;
}

@media (max-width:800px){
    .text-right h1{
        font-size: 3em;
        
    }
    .left video{
        max-height: 200px;
        width: 100%;
    }
    .video-status{
        /* overflow-x: scroll; */
        gap: 5px;
        flex-flow: nowrap !important;
    }
    .status{
        overflow-x: auto;
        width: 100%;
    }
    .status::-webkit-scrollbar{
        display: none;
    }
    .video-status .video video{
        height: 80px;
        width: 80px;
        border-radius: 100%;
        border: 3px solid #000;
        padding: 3px;
    }
    .skillsmain{
        padding: 20px;
        min-height: 70vh !important;
      
    }
   
    .text-right h1{
        font-size: 2em;
    }
    .text-right p{
        font-size: 1em;
    }
}
.video video{
    width: 100%;
    object-fit: cover;
    height: 350px;
    animation: video 1s linear;
    /* border: 5px solid #000; */
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    border-radius: 30px;

}
.video{
    position: relative;
    text-align: center;
}
.play-video{
    width: 80px;
    height: 80px;
    background-color: #f5a30d;
    color: #151515;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -10px;
    border-radius: 100px;
    left: 30px;
}
.play-video .bi-play-fill{
    font-size: 2.5rem;
}
@media (max-width:578px){
    .play-video{
        width: 40px;
        height: 40px;
    }
    .play-video .bi-play-fill{
        font-size: 1rem;
    }
    .right video{
        border: 2px solid #febb2a !important;
        padding: 5px;
    }
    .skillsmain{
        margin-bottom: 0 !important;
    }
}

.play-video:hover{
    transform: scale(1.1);
    transition: 1s all;
    cursor: pointer;
}
@keyframes video{
    from{
        transform: translateY(100px);
    }
    to{
        transform: translateY(0);
    }
}
.sticky-component {
    position: -webkit-sticky;
    position: sticky;
    top: 0; /* Stick the component to the top of the viewport */
    background-color: white; /* Adjust as needed */
    /* Other styling properties */
  }