
.benifits-component-card{
    padding: 10px;
    text-align: center;
    min-height: 300px;
    margin-bottom: 20px;
    border-radius: 30px;
    background-color: var(--light-color);
}
.benifits-component-card img{
    width: 80px;
    height: 80px;
}