
.navbar-nav .nav-link {
    color: #403f3f;
    font-size: 1.1rem;
    padding: 10px;

  }
  .elite-link{
      background:var(--gold) !important;
      color: #ffffff !important;
      border-radius: 100px;
      padding: 5px 20px !important;
      font-size: 1rem !important;

  }
  .navbar-brand img{
    width: 180px;
}
  .bi-list{
    color: var(--white-color) !important;
    font-size: 1.4em;
  }
 
  /* .dropdown-menu .list-group-item:hover{
    background-color: #dedede !important;
  } */
  .dropdown-item{
    padding: 0px !important;
  }
  .dropdown .dropdown-menu {
    display: none;
    padding: 0;
    border: none;
  }
  .dropdown:hover > .dropdown-menu,
  .dropend:hover > .dropdown-menu {
    display: block;
    margin-top: 0.125em;
    margin-left: 0.125em;
  }
  @media screen and (min-width: 769px) {
    .dropend:hover > .dropdown-menu {
      position: absolute;
      top: 0;
      left: 100%;
    }

  }
  nav{
    background-color: #ffffff5a;
    backdrop-filter: blur(20px);
    gap:30px !important;
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
    border-bottom: 1px solid #ededed;

  }
  .nav-top{
    background-color: var(--danger);
    text-align: center;
    margin: 0;

  }
  header a{
    min-width: fit-content;
  }
  header{
    position: sticky !important;
    top: 0 !important;
    background-color: var(--color-dark-1);
    z-index: 100;
  }
  .list-group-item{
    padding: 0 !important;
  }
  @media(max-width:769px){
    .navbar-brand img{
      width: 150px;
  }
    nav{
      padding: 10px !important;
      z-index: 10;
    }
    .navbar-nav .nav-link{
      color: #fff;
    }
    /* .navbar-nav .nav-link:hover{
      color: #d4d3d3 !important;
    } */
    .dropdown-toggle{
      color: #fff !important;
    }
    nav button{
      margin-bottom: 10px !important;
    }
  }
  .company-list-item, .mobile-list-item{
    
  }
  .mobile-list-item .nav-link{
    padding: 10px !important;
    font-size: 1rem !important;
  }
  /* Custom styles for wide dropdown menu */
.dropdown-menu-wide {
  width: 300px; /* Adjust the width as needed */
  max-height: 400px; /* Limit the height */
  overflow-y: auto; /* Enable scrolling */
}

/* General mobile list item styling */
.mobile-list-item .nav-link {
  font-size: 1rem;
  padding: 0.5rem;
}

/* Styling for the button in the offcanvas contact */
.btn.bg-success-2 {
  background-color: #28a745; /* Customize the color */
}

.navbar .nav-link {
  padding: 0.5rem 1rem;
}

@media (min-width: 992px) {
  .navbar-nav .nav-item .dropdown-menu {
    max-height: 400px; /* Limit the height */
    overflow-y: auto; /* Enable scrolling */
  }
}


