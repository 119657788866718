
.scroll-to-top {
    display: none;
    position: fixed;
    bottom: 20px;
    left: 50%; 
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 12;
  }
  
  .scroll-to-top.visible {
    display: block; 
  }