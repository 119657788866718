@import "~react-image-gallery/styles/css/image-gallery.css";
.gallery-image {
    cursor: pointer;
    margin: 10px;
    overflow: hidden;
    transition: transform 0.2s;
  }
  .gallery-card{
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #8f8f8f7b;
  }
  
  .gallery-image:hover, .gallery-image:hover~.gallery-image-text {
    transform: scale(1.05);
  }
  
  .image-container {
    position: relative;
    padding-bottom: 100%; /* Create a square container */
    overflow: hidden;
  }
  
  .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain image aspect ratio */
    border-radius: 20px;
  }
  .gallery-card-body{
    background-color:#73717172;
    padding: 10px;
    border-radius: 20px;
  }
  
  .square-lightbox {
    position: relative;
    text-align: center;
    width: 100%;
    padding-bottom: 100%; /* Create a square container for the lightbox */
    overflow: hidden;
  }
  
  .square-lightbox img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; 
 
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .close-button button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .close-button button:focus {
    outline: none;
  }
  .gallery-card{
    position: relative;
    overflow: hidden;
  }

  .gallery-image-text{
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#0000001f, #000000);
    bottom: 0;
    left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
  }
  .main-modal-gallery{
    backdrop-filter: blur(30px);
  }
  @media (max-width:600px){
    .gallery-image-text p{
        font-size: 0.6em;
    }
  }