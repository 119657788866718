.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #007bff; /* Progress bar color */
    transition: width 0.3s ease-in-out;
    z-index: 9999;
  }
  
  .progress {
    width: 100%;
    height: 100%;
  }
  
  .progress-bar.loading {
    width: 100%;
  }
  
  .progress-bar.loaded {
    width: 0;
  }