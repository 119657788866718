.hero-text{
    padding: 10px;
    position: relative;
    z-index: 10;
}
.register-container-home{
    border-radius: 10px;
}
.hero-image img{
    border-radius:10px;
    height: 300px;
}


@media (max-width:1100){
    .hero-text{
        padding: 10px !important;
        text-align: center;
    }
}
@media (max-width:768px){
    .hero-text{
        padding: 10px !important;
        text-align: center;
    }
    .register-container-home .form-control{
            margin-bottom: 10px !important;
    }
    .hero-image{
        position: relative;
        min-height: fit-content !important;
    }

}