
.companies{
    /* background: url('../images/low-poly-grid-haikei.svg') no-repeat center; */
    background-size: cover;
    background: var(--blue-light);
    min-height: 150px;
    padding: 50px;
    color:#fff;
    border-radius: 20px;
}
.companies .slick-arrow{
    display: none !important;
}
.main-company-container{
    padding: 6% !important;
}
.companies-brands-container{
    min-height: 100% !important;
    background-color: transparent;
    overflow: hidden;
}
.companies-brands-container img{
    width: 80px;
    object-fit: contain;
}
.all-company-card{
    background-color: #fff;
    border-radius: 20px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}
.companies-brands-container .all-company-card img:hover{
    transform: scale(1.2);
    transition: 0.3s ease-in-out;
    cursor: pointer;
    filter: grayscale(0%) saturate(100%) brightness(100%);
}
@media (max-width:768px){
    .main-company-container{
        padding: 10px !important;
    }
}